<template>
    <div>
        <v-img
            class="white--text align-top"
            height="250px"
            gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
            :src="`/images/farmacia.png`"
        >
            <v-card-title justify-top>
                Farmacia
            </v-card-title>
        </v-img>

        <v-tabs color="primary" v-model="tab">
            <v-tab>
                Existencias
            </v-tab>
            <v-tab>
                Movimientos
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <farmacia-existencias/>
            </v-tab-item>
            <v-tab-item>
                <farmacia-movs/>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
export default {
    components:{
        'farmacia-existencias':()=>import('@/components/farmacia/FarmaciaLista'),
        'farmacia-movs':()=>import('@/components/farmacia/FarmaciaReporteMovs'),
    },
    data:()=>({
        tab:0,
    }),

}
</script>

<style>

</style>